import * as Sentry from '@sentry/svelte';
import { dev } from '$app/environment';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client'

const onError = init(   
  "https://a70d018acfb5db38f98e98e68304355a@o4506685238542336.ingest.sentry.io/4506685239918592",
  {
    sentryOptions: {
      dsn: "https://a70d018acfb5db38f98e98e68304355a@o4506685238542336.ingest.sentry.io/4506685239918592",
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
      integrations: [], //Sentry.replayIntegration()],
      // TODO turn off in dev
      enabled: true // !dev
    }
  }
)

export const handleError = onError((e, sentryEventId) => {
  console.trace(e, sentryEventId);
  // Your Error Handler
});